@import "@root/theme/index.scss";
@import "@root/theme/colors.scss";

.root {
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  height: 4.5rem;
  background-color: $primaryDarkColor;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding: 0 64px;
  display: flex;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
}
