@import "@root/theme/colors.scss";
@import "@root/theme/size.scss";

.root {
  background-color: $white100Color;
  width: 100%;
  height: max-content;
  position: relative;
}

.table {
  height: calc(100vh - 400px);
  max-height: 400px;
  width: 100%;
  overflow: hidden;
  border-radius: $p08;
  background: $white100Color;
  & *::-webkit-scrollbar-track {
    background: $white100Color;
  }
}
