@import "@root/theme/colors.scss";
@import "@root/theme/size.scss";

.row {
  display: flex;
  align-items: center;
  padding: 0;
  height: 100%;
}

.tableCheckbox {
  margin-left: 12px;
  margin-right: 2px;
}

.header {
  display: flex;
  user-select: none;
  align-items: center;
  background: $primary10Color;
}

.footer {
  display: flex;
  user-select: none;
  background: $primary10Color;
}

.group {
  flex: 1 1 100%;
  height: 100%;
}

.staticGroup {
  flex: 1 0 auto;
}

.cell {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-size: 12px;
}

.bodyCell {
  border-bottom: 1px solid $black20Color;
}

.headerCell {
  display: flex;
  align-items: center;
  height: 48px;
  border-bottom: 1px solid $black20Color;
}
.headerCellInner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.footerCell {
  border-top: 1px solid $black20Color;
}

.clickable {
  cursor: pointer;
}

.sortArrow {
  min-width: 10px;
  font-size: 10px;
  margin-left: 8px;
  display: flex;
  align-items: flex-start;
}

.header .group {
  border-color: transparent;
}
